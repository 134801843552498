<template>
  <div class="bg-gray-700 mt-2 p-2 text-left">
    <div class="block -mt-1 -mb-1">
      <template v-for="select,key in selectedOpts">
        <div class="my-1 flex">
          <a
            href="#"
            class="inline-flex items-center text-gray-600 hover:text-white pr-2"
            @click.prevent="removeRow(key)"
          >
            <fa-icon icon="minus-circle" />
          </a>

          <select
            :value="selectedOpts[key]"
            class="form-select inline-flex font-mono focus:bg-gray-700 focus:outline-none bg-gray-600 border border-gray-600 text-white p-1 rounded text-xs focus:border-gray-500"
            @change="updateSelectedOpts($event, key)"
          >
            <option value="" />
            <template v-for="option,k in availableOpts">
              <option>
                {{ option }}
              </option>
            </template>
            </option>
          </select>
        </div>
      </template>

      <div class="mt-2 mb-3">
        <div class="flex">
          <div
            class="flex-1 ml-4 text-white p-1 px-1 cursor-pointer"
            @click.prevent="addRow"
          >
            <a
              href="#"
              class="rounded btn primary text-xs"
            >+ Row</a>
          </div>
          <div class="flex-1 p-1 text-right">
            <fa-icon icon="trash-can" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MappingInputTable',

  props: ['available', 'selected'],

  data () {
    return {
      selectedOpts: []
    }
  },

  computed: {

    availableOpts () {
      let opts = []

      this.available.forEach(avail => {
        avail.uvs.forEach(uv => {
          opts.push(avail.name + ':' + uv)
        })
      })

      return opts
    }
  },

  mounted () {
    this.setupOpts()
  },

  methods: {

    updateSelectedOpts (event, key) {
      this.selectedOpts[key] = event.target.value
      this.emitChange()
    },

    addRow () {
      this.selectedOpts.push('')
    },

    removeRow (i) {
      this.selectedOpts.splice(i, 1)
      this.emitChange()
    },

    setupOpts () {
      let opts = []
      this.selected.forEach(select => {
        opts.push(select.name + ':' + select.uv)
      })

      this.selectedOpts = opts
    },

    emitChange () {
      this.$emit('changed', this.selectedOpts)
    }
  }

}
</script>
